export const regionStore = {
	state: {
		name: 'region',
		title: 'Region',
		titlePlural: 'Regions',
		nav: {
			sideNav: false
		},
		search: {
			endPoint: 'region',
			formFields: {},
			resultFields: {}
		},
		object: {}
	}
}